import ScemaDesktop from "../assets/how_we_work/spo_desktop.svg";
import ScemaTablet from "../assets/how_we_work/spo_tablet.svg";
import ScemaMobile from "../assets/how_we_work/spo_mobile.svg";

import advanced_solutions from "../assets/icons/team_members_skills/advanced_solutions.png";
import devops from "../assets/icons/team_members_skills/devops.png";
import figma from "../assets/icons/team_members_skills/figma.png";
import infrastrucutre from "../assets/icons/team_members_skills/infrastrucutre.png";
import js from "../assets/icons/team_members_skills/js.png";
import management from "../assets/icons/team_members_skills/management.png";
import project_management from "../assets/icons/team_members_skills/project_management.png";
import python from "../assets/icons/team_members_skills/python.png";
import strategy from "../assets/icons/team_members_skills/strategy.png";
import user_experience from "../assets/icons/team_members_skills/user_experience.png";
import user_interface from "../assets/icons/team_members_skills/user_interface.png";

import Renaud from "../assets/icons/team_members/Renaud.png";
import Dmitry from "../assets/icons/team_members/Dmitry.png";
import Mark from "../assets/icons/team_members/Mark.png";
import Karina from "../assets/icons/team_members/Karina.png";
import Aglae from "../assets/icons/team_members/Aglae.png";
import Mohamed from "../assets/icons/team_members/Mohamed.png";

export const WorkData = [
  {
    number: 1,
    period: "1-2 weeks*",
    title: "Project scoping and agreement",
    items: ["Proposal and contract"],
    text: [
      "Client shares necessary information to lay out the scope of the project: goals, business requirements, timeframe.",
      "Proposal and quotation are discussed.",
      "Contract is signed.",
    ],
  },
  {
    number: 2,
    period: "1-2 weeks*",
    title: "UX/UI design",
    items: ["User flows and personas", "UX/UI design"],
    text: [
      "Business analyst elaborates user flows and personas with the client.",
      "UX/UI designer integrates this work in proposals of interface for review.",
      "Client validates the chosen design option.",
    ],
  },
  {
    number: 3,
    period: "1-2 weeks*",
    title: "MVP validation",
    items: ["Validated MVP", "Timeframe for dev and testing"],
    text: [
      "Application proposal is reviewed and adjusted with the client based on prioritization of features to define Minimum Viable Product.",
    ],
  },
  {
    number: 4,
    period: "4 weeks*",
    title: "Development and Testing",
    items: ["Developed features according to the validated MVP"],
    text: [
      "Application is developed in iteration of sprints corresponding to consistent groups of features.",
      "Each sprint ends with user acceptance testing and corresponding adjustments.",
    ],
  },
  {
    number: 5,
    period: "1 weeks*",
    title: "Handover",
    items: [
      "User documentation and training",
      "Technical documentation and environment set up",
    ],
    text: ["Key users are trained.", "Environment is set up."],
  },
];

export const OurTeams = [
  // {
  //   icon: Renaud,
  //   title: "Renaud",
  //   description: "Director",
  //   skills: [
  //     { title: "Strategy", icon: strategy },
  //     { title: "Python", icon: python },
  //     { title: "Infrastrucutre", icon: infrastrucutre },
  //     { title: "Management", icon: management },
  //   ],
  // },
  {
    icon: Dmitry,
    title: "Dmitry",
    description: "WebDev team leader",
    skills: [
      { title: "Strategy", icon: strategy },
      { title: "Python", icon: python },
      { title: "Infrastrucutre", icon: infrastrucutre },
      { title: "Advanced solutions", icon: advanced_solutions },
    ],
  },
  {
    icon: Aglae,
    title: "Aglae",
    description: "Project manager",
    skills: [
      { title: "Strategy", icon: strategy },
      { title: "Infrastrucutre", icon: infrastrucutre },
      { title: "Project management", icon: project_management },
    ],
  },
  {
    icon: Karina,
    title: "Karina",
    description: "UX/UI designer",
    grade: "Senior",
    skills: [
      { title: "Figma", icon: figma },
      { title: "User experience", icon: user_experience },
      { title: "User interface", icon: user_interface },
    ],
  },
  {
    icon: Mark,
    title: "Mark",
    description: "Fullstack developer",
    grade: "Senior",
    skills: [
      { title: "Python", icon: python },
      { title: "DevOps", icon: devops },
      { title: "Infrastrucutre", icon: infrastrucutre },
    ],
  },
  {
    icon: Mohamed,
    title: "Mohamed",
    description: "Fullstack developer",
    grade: "Senior",
    skills: [
      { title: "JavaScript", icon: js },
      { title: "Python", icon: python },
      { title: "DevOps", icon: devops },
      { title: "Infrastrucutre", icon: infrastrucutre },
    ],
  },
];

export const Notification =
  "* Time estimation based on the minimum MVP package. Timeframe to be discussed upon project scoping and agreement.";

export const SchemaDevice = {
  desktop: ScemaDesktop,
  tablet: ScemaTablet,
  mobile: ScemaMobile,
};
